<template>
  <v-card class="elevation-0">
    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      hide-default-footer
      class="elevation-0"
      disable-pagination
      show-expand
      item-key="unique"
    >
      <template #item.total_file="{ item }">
        {{ countAllFile(item) }}
      </template>
      <template #item.data-table-expand="{ item, expand, isExpanded }">
        <div class="d-flex flex-row">
          <v-btn icon small class="primary" dark @click="expand(!isExpanded)">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
      <template #item.teachers="{ item, value }">
        <v-row v-if="value.length > 1" no-gutters align="start">
          <span v-if="!item.viewMore">
            <span>{{ value[0].name }}</span>
          </span>
          <span v-else>
            <div v-for="ht in value" :key="ht.id" class="my-2">
              {{ ht.name }}
            </div>
          </span>
          <v-spacer />
          <v-btn
            small
            icon
            @click="item.viewMore = !item.viewMore"
            :class="item.viewMore ? 'mt-2' : ''"
          >
            <v-icon v-if="item.viewMore">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </v-row>
        <v-row v-if="value.length == 1" no-gutters>{{ value[0].name }}</v-row>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            :headers="expandHeaders"
            :items="item.teachers"
            hide-default-footer
            class="elevation-0 my-4"
            disable-pagination
          >
            <template #item.total_file="{ item }">
              {{ item.classroom.file.length }}
            </template>
            <template #item.action="{ item }">
              <v-btn
                small
                dark
                icon
                class="primary mr-2"
                @click="openDialog(item)"
              >
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <Pagination
      :length="table.length"
      :total="table.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
    />
    <DialogSilabus
      :dialog="dialogEdit"
      :dataEdit="dataEdit"
      :name="$t('app.document')"
      isEdit
      @close="closeDialog"
    />
  </v-card>
</template>

<script>
export default {
  props: {
    dataTable: Array,
    loading: Boolean,
    table: Object,
    documentType: String
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    DialogSilabus: () => import("..//dialog")
  },
  data() {
    return {
      dataEdit: {},
      dialogEdit: false,
      expandHeaders: [
        {
          text: this.$i18n.t("app.teacher"),
          value: "name",
          sortable: false
        },
        {
          text: "Total File",
          value: "total_file",
          sortable: false
        },
        {
          text: this.$i18n.t("app.action"),
          value: "action",
          sortable: false,
          width: 100
        }
      ],
      tableHeaders: [
        {
          text: this.$i18n.t("app.class"),
          value: "class_name",
          sortable: false
        },
        {
          text: this.$i18n.t("app.teacher"),
          value: "teachers",
          sortable: false
        },
        {
          text: "Total File",
          value: "total_file",
          sortable: false
        },
        {
          text: this.$i18n.t("app.subjects"),
          value: "lesson_name",
          sortable: false
        }
      ]
    };
  },
  methods: {
    countAllFile(item) {
      return item.teachers
        .map(v => v.classroom.file.length)
        .reduce((prev, curr) => prev + curr);
    },
    openDialog(item) {
      this.dialogEdit = true;
      this.dataEdit = {
        teacher: item.teacher,
        lesson: item.lesson,
        classrooms: [item.classroom.id],
        files: item.classroom.file,
        schoolYear: item.school_year,
        documentType: this.documentType
      };
    },
    closeDialog() {
      this.dialogEdit = false;
      this.getData();
    },
    getData() {
      this.$emit("getData");
    },
    paginateChange(page, limit) {
      this.$emit("paginateChange", page, limit);
    }
  }
};
</script>
